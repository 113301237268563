// extracted by mini-css-extract-plugin
export var bannerBlock = "fitness-sporttech-module--bannerBlock--38504";
export var bannerWrapper = "fitness-sporttech-module--bannerWrapper--9d555";
export var benefitCard = "fitness-sporttech-module--benefitCard--17275";
export var billetShadow = "fitness-sporttech-module--billet-shadow--810d2";
export var cardsWrapper = "fitness-sporttech-module--cardsWrapper--eb064";
export var casesTitle = "fitness-sporttech-module--casesTitle--dc528";
export var colorScheme__background__grayPale = "fitness-sporttech-module--colorScheme__background__gray-pale--2adc9";
export var headline = "fitness-sporttech-module--headline--5a610";
export var headline_grid = "fitness-sporttech-module--headline_grid--6ba82";
export var icomoon = "fitness-sporttech-module--icomoon--66565";
export var lowercase = "fitness-sporttech-module--lowercase--7de8e";
export var mobileBtnWrapper = "fitness-sporttech-module--mobileBtnWrapper--3e562";
export var sportTechBlock = "fitness-sporttech-module--sportTechBlock--b3d84";
export var textTwoColumns = "fitness-sporttech-module--textTwoColumns--eb47a";
export var title = "fitness-sporttech-module--title--e3e43";
export var transition = "fitness-sporttech-module--transition--f4639";
export var transitionBackground = "fitness-sporttech-module--transition-background--88d9b";
export var transitionReverse = "fitness-sporttech-module--transition-reverse--2f151";
export var uppercase = "fitness-sporttech-module--uppercase--08eb7";