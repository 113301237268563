import React from "react"

export const TITLES = {
  banner: {
    title: (
      <>
        <strong>
          Our fitness
          <br /> app
        </strong>{" "}
        developers offer the most advanced solution
      </>
    ),
    strongText: "Sports software development company",
    uppercase: (
      <>
        Create own
        <br /> fitness app with
        <br /> LaSoft
      </>
    ),
    withIndent: true,
  },
  options: {
    title: (
      <>
        <strong>
          Health &<br /> Fitness App
        </strong>{" "}
        Development Company
      </>
    ),
    strongText: "as one-stop-shop",
    uppercase: "We create",
    withIndent: true,
  },
  benefits: {
    title: (
      <>
        Elevate your fitness business
        <br />
        <strong> with our SporTech App Development</strong>
      </>
    ),
    strongText: "LaSoft as your tech partner",
    uppercase: "Our Benefits",
    withIndent: true,
  },
  case_studies: {
    title: (
      <>
        case studies we're <strong>proud&nbsp;of</strong>
      </>
    ),
    strongText:
      "We are proud of the results of our work with clients so, we talk about them in the case study ",
    uppercase: (
      <>
        We work with
        <br /> your ideas
      </>
    ),
    withIndent: true,
  },
}

export const SERVICES = {
  items: [
    {
      id: 1,
      title: "Fitness Management System",
      text:
        "Our team offers full-cycle fitness and sports app development with advice on enhancing your fitness software. We use top-notch technology to help you digitalize, monetize, and maintain your business.",
    },
    {
      id: 2,
      title: "Marketplace for Online Trainings",
      text:
        "We can implement different features like sending emails automatically, processing payments, intuitive UX and engaging UI, history of interactions. You can get all the vital features in your health & fitness app development offer from LaSoft.",
    },
    {
      id: 3,
      title: "Membership Management Solution",
      text:
        "We can create a membership management platform for sports and fitness businesses to enhance the client experience through content, communication, financial, and customer support management.",
    },
    {
      id: 4,
      title: "Sport and Fitness App Development",
      text:
        "We can offer personalized fitness mobile app development that functions for your target audience, much like a personal fitness trainer.",
    },
    {
      id: 5,
      title: (
        <>
          You will get a fully functional <br />
          app with
        </>
      ),
      text: (
        <p>
          admin panel; <br />
          to set weight goals to reach; dietary plans; <br />
          video trainings, <br />
          contact forms to reach your personal trainer
        </p>
      ),
    },
    {
      id: 6,
      title: "Gym Workout App Development",
      text:
        "We can create a workout app to interact with your clients online, who want to obtain fitness exercises, training schedules, and guidelines. These solutions aim to offer audio or video guidance on finishing a series of exercises.",
    },
  ],
}

export const BENEFITS = [
  {
    id: 1,
    title: "Customized App Development for Your Fitness Needs",
    text:
      "At our company, we understand the importance of staying active and healthy. That's why we offer customized SporTech app development services to help you reach your fitness goals. With the growing interest in fitness and technology, our team is dedicated to creating innovative and engaging apps that will elevate your fitness journey.",
    icon: "icon-settings",
  },
  {
    id: 2,
    title: "Cutting-Edge Technology for Optimal Performance",
    text:
      "Our team of developers stays up-to-date with the latest technology and trends in the fitness industry. We use cutting-edge technology to create apps for your clients that will enhance their workout experience and help them achieve optimal performance. The functionality will let your clients get personalized workout plans and track the progress.",
    icon: "icon-rocket",
  },
  {
    id: 3,
    title: "User-Friendly and Interactive Design",
    text:
      "We believe that fitness should be accessible to everyone, which is why we prioritize user-friendly and interactive design in our app development process. Our apps are designed to be easy to navigate and use, making it simple for anyone to incorporate fitness into their daily routine.",
    icon: "icon-touch",
  },
  {
    id: 4,
    title: "AI Algorithms to Follow User’s Needs",
    text:
      "With the help of AI, your app will offer personalized workout and meal plans based on every user's specific needs and preferences. It will also provide real-time feedback and motivation to keep your users on track.",
    icon: "bg-ai",
  },
]
