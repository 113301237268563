import React from "react"
import { Link } from "gatsby"

import "../assets/styles/portfolio-chapter.scss"
import { IMG } from "../const"

const Images = ({ item, dataPage }) => {
  const db = `${IMG.INDEX}/`
  const mainImageExtension = item.mainPortfolioImage.includes(".png")
    ? "png"
    : "jpg"

  const sourceUrls = (nums, imageLink, type) => {
    if (dataPage && item.secondPortfolioImage) {
      return `${db}${imageLink}data-analytics/${nums[0]}.${type},	${db}${imageLink}data-analytics/${nums[1]}.${type} 2x,  ${db}${imageLink}data-analytics/${nums[2]}.${type} 3x`
    }

    return `${db}${imageLink}${nums[0]}.${type},	${db}${imageLink}${nums[1]}.${type} 2x,  ${db}${imageLink}${nums[2]}.${type} 3x`
  }

  const sourceBgs = (imageLink, nums, type) => {
    return `${db}${imageLink}${nums[0]}.${type},	${db}${imageLink}${nums[1]}.${type} 2x`
  }

  return (
    <>
      <picture className="portfolioChapter__singleProjectSummary__moodPic">
        <source
          srcSet={sourceBgs(item.imageLink, ["bg", "bg1712"], "avif")}
          type="image/avif"
        />
        <source
          srcSet={sourceBgs(item.imageLink, ["bg", "bg1712"], "webp")}
          type="image/webp"
        />
        <source srcSet={sourceBgs(item.imageLink, ["bg", "bg1712"], "jpg")} />
        <img
          src={`${IMG.INDEX}/${item.mainBgImage}`}
          alt={item.metaTitle}
          width="1081"
          height="720"
          loading="lazy"
        />
      </picture>
      <picture
        className={`portfolioChapter__singleProjectSummary__screenshotPlaceholder`}
      >
        <source
          media="(max-width: 428px)"
          srcSet={sourceUrls([382, 764, 984], item.imageLink, "avif")}
          type="image/avif"
        />
        <source
          media="(max-width: 428px)"
          srcSet={sourceUrls([382, 764, 984], item.imageLink, "webp")}
          type="image/webp"
        />
        <source
          media="(max-width: 428px)"
          srcSet={sourceUrls(
            [382, 764, 984],
            item.imageLink,
            mainImageExtension
          )}
        />
        <source
          media="(min-width: 429px)"
          srcSet={sourceUrls([594, 1188, 1782], item.imageLink, "avif")}
          type="image/avif"
        />
        <source
          media="(min-width: 429px)"
          srcSet={sourceUrls([594, 1188, 1782], item.imageLink, "webp")}
          type="image/webp"
        />
        <source
          media="(min-width: 429px)"
          srcSet={sourceUrls(
            [594, 1188, 1782],
            item.imageLink,
            mainImageExtension
          )}
        />
        <img
          src={item.mainPortfolioImage}
          width="288"
          height="158"
          alt={item.metaTitle}
          loading="lazy"
        />
      </picture>
    </>
  )
}

const PortfolioImagesContainer = ({ item, dataPage }) => (
  <>
    {item.externalLink ? (
      <a href={item.externalLink} target="_blank" rel="noopener noreferrer">
        <Images item={item} dataPage={dataPage} />
      </a>
    ) : item.slug ? (
      <Link to={`/${item.slug}/`}>
        <Images item={item} dataPage={dataPage} />
      </Link>
    ) : (
      <div>
        <Images item={item} dataPage={dataPage} />
      </div>
    )}
  </>
)

export default PortfolioImagesContainer
